.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.flex-child {
  flex: 1;
}
.scroll {
  overflow-y: scroll;
  height: 100vh;
}
.flex-child:first-child {
}

.bottom-right {
  position: absolute;
  bottom: 6%;
  right: 3%;
}

.App-header {
  background-color: #282c34;
  min-width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
